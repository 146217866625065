<template>
  <div class="portfolio-promo">
    <p>Erkunden Sie unser Portfolio, um einen Eindruck von unserer Arbeit zu erhalten.</p>
    <button @click="redirectToPortfolio" class="portfolio-button">Zum Portfolio</button>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToPortfolio() {
      // Redirect to the '/portfolio' page
      this.$router.push('/portfolio');

      // Jump to the top of the page without any scrolling animation
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'instant'
      })
    },
  },
};
</script>

<style scoped>
.portfolio-promo {
  text-align: center;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 20vh;
}

p{
  color: black;
  font-weight: bold;
  font-size: 21px;
}

.portfolio-button {
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 20px;
  background: #b5bfae;
  text-decoration: none;
  color: #fff;
  border: 3px solid #0e0726;
  border-radius: 10px;
  margin-top: 10px;
}

.portfolio-button:hover {
  background: #ffffff;
  color: #b5bfae;
}

@media screen and (max-width: 1034px) {
  .portfolio-promo {
    height: 25vh;
  }
}
</style>
