import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from "@/views/Datenschutz";
import Portfolio from "@/views/Portfolio.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
