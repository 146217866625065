<template>
  <div class="portfolio">
    <PortfolioLandingPage />
    <NavigationMenu />
    <PortfolioSection id="PortfolioSection"  number="1" name="Corpshaus 2.0" subname="Intranet für Corpsstudenten" bgColor="#7EB8CB" textColor="#ffffff" :content="suusContent.info.content" :styling="suusContent.info.styling" :logo="SuusLogo">
      <PortfolioSubsection v-for="(content,index) of suusContent.subsections" :key="index" :content="content" />
    </PortfolioSection>
    <PortfolioSection number="2" name="Merci Gestalter" subname="Dein ganz individuelles Merci Geschenk" bgColor="#D0EABF" textColor="#131B26" :content="merciContent.info.content" :styling="merciContent.info.styling" :logo="MerciLogo">
      <PortfolioSubsection v-for="(content,index) of merciContent.subsections" :key="index" :content="content" />
    </PortfolioSection>
    <PortfolioSection number="3" name="Flavoro" subname="Restaurant-Webseiten leicht gemacht" bgColor="#333333" textColor="#ffffff" :content="flavoroContent.info.content" :styling="flavoroContent.info.styling" :logo="FlavoroLogo">
      <PortfolioSubsection v-for="(content,index) of flavoroContent.subsections" :key="index" :content="content" />
    </PortfolioSection>
    <PortfolioSection number="4" name="Tagea" subname="Die Lösung für soziale Einrichtungen" bgColor="#FF8888" textColor="#131B26" :content="tremazeContent.info.content" :styling="tremazeContent.info.styling" :logo="TremazeLogo">
      <PortfolioSubsection v-for="(content,index) of tremazeContent.subsections" :key="index" :content="content" />
    </PortfolioSection>
    <PortfolioSection number="5" name="FDP" subname="Politische Partei" bgColor="#FFED00" textColor="#000000" :content="fdpContent" :styling="fdpStyling" :logo="FDPLogo">
      <PortfolioSubsection v-for="(content,index) of fdpSubsectionContent" :key="index" :content="content" />
    </PortfolioSection>
    <PortfolioSection number="6" name="Testat.io" subname="StartUp aus Paderborn" bgColor="#FFB033" textColor="#ffffff" :content="testatioContent.info.content" :styling="testatioContent.info.styling" :logo="TestatioLogo">
      <PortfolioSubsection v-for="(content,index) of testatioContent.subsections" :key="index" :content="content" />
    </PortfolioSection>
    <PortfolioSection number="7" name="Ruhl App" subname="StartUp aus Kassel" bgColor="#CDD971" textColor="#131B26" :content="ruhlContent" :styling="ruhlStyling" :logo="RuhlLogo">
      <PortfolioSubsection v-for="(content,index) of ruhlSubsectionContent" :key="index" :content="content" />
    </PortfolioSection>
    <PortfolioSection number="8" name="Chinder" subname="Social StartUp" bgColor="#CFC1F5" textColor="#0E0726" :content="chinderContent" :styling="chinderStyling" :logo="ChinderLogo">
      <PortfolioSubsection v-for="(content,index) of chinderSubsectionContent" :key="index" :content="content" />
    </PortfolioSection>

    <ScrollButton />
    <Footer />
  </div>
</template>

<script>
import PortfolioLandingPage from "@/components/PortfolioLandingPage.vue";
import PortfolioSection from "@/components/PortfolioSection.vue";
import PortfolioSubsection from "@/components/PortfolioSubsection.vue";
import Footer from "@/components/Footer.vue";
import ChinderLogo from "@/assets/chinder/ChinderLogo.svg";
import FDPLogo from "@/assets/fdp/FDPLogo.svg";
import RuhlLogo from "@/assets/ruhl/RuhlLogo.svg";
import TestatioLogo from "@/assets/testatio/TestatioLogo.svg";
import testatioContent from "@/assets/testatio/testatio-content.js";
import ruhlSubsectionContent from "@/assets/ruhl/ruhl-content.js";
import chinderSubsectionContent from "@/assets/chinder/chinder-content.js";
import fdpSubsectionContent from "@/assets/fdp/fdp-content.js";
import flavoroContent from "@/assets/flavoro/flavoro-content.js";
import tremazeContent from "@/assets/tremaze/tremaze-content";
import FlavoroLogo from "@/assets/flavoro/FlavoroLogo.svg";
import ScrollButton from "@/components/ScrollButton.vue";
import NavigationMenu from "@/components/NavigationMenu.vue";
import TremazeLogo from "@/assets/tremaze/TremazeLogo.svg";
import MerciLogo from "@/assets/merci/MerciLogo.png";
import merciContent from "@/assets/merci/merci-content.js";
import SuusLogo from "@/assets/suus/SuusLogo.png";
import suusContent from "@/assets/suus/suus-content.js";

export default {
  name: "app-portfolio",
  components: {
    NavigationMenu,
    PortfolioLandingPage,
    PortfolioSection,
    PortfolioSubsection,
    Footer,
    ScrollButton
  },
  setup() {
    const ruhlContent =
        "Die Ruhl App hilft Fitnessbegeisterten den richtigen Trainingsplan zu finden. Mit Community Features und Nutzerdaten lernt der intelligente Algorithmus dazu, um so die optimalen Pläne vorzuschlagen.\nWir haben für Ruhl App die nativen Mobileapps und das Design entwickelt.";
    const chinderContent =
        "Chinder nutzt eine Swipefunktion wie bei Tinder um vor allem junge Menschen für das Spenden zu begeistern. Organisationen sind hierbei die Kandidaten und Spender können mit einem Swipe einen kleinen Betrag an diese spenden.\nFür Chinder entwickelten wir eine Cross-Plattform Mobile-App.";
    const fdpContent =
        "Wir halfen Kandidaten der FDP bei der Umsetzung des Corporate Designs in konkrete Werbemittel. Dabei übernahmen wir die Konzeption und Herstellung von Print und digitalen Medien.";

    const ruhlStyling = {
      name: {
        family: "montserrat, sans-serif",
        weight: "400",
      },
      subname: {
        family: "montserrat, sans-serif",
        weight: "700",
      },
      content: {
        family: "montserrat, sans-serif",
        weight: "400",
        size: "24px",
      },
    };

    const chinderStyling = {
      name: {
        family: "congenial, sans-serif",
        weight: "400",
      },
      subname: {
        family: "congenial, sans-serif",
        weight: "700",
      },
      content: {
        family: "basic-sans, sans-serif",
        weight: "400",
        size: "24px",
      },
    };

    const fdpStyling = {
      name: {
        family: "futura-pt-condensed, sans-serif",
        weight: "800",
      },
      subname: {
        family: "futura-pt-condensed, sans-serif",
        weight: "800",
      },
      content: {
        family: "futura-pt-condensed, sans-serif",
        weight: "500",
        size: "24px",
      },
    };

    return {
      ruhlContent,
      chinderContent,
      fdpContent,
      ruhlStyling,
      chinderStyling,
      fdpStyling,
      TestatioLogo,
      RuhlLogo,
      ChinderLogo,
      FDPLogo,
      testatioContent,
      ruhlSubsectionContent,
      chinderSubsectionContent,
      fdpSubsectionContent,
      FlavoroLogo,
      flavoroContent,
      TremazeLogo,
      tremazeContent,
      MerciLogo,
      merciContent,
      SuusLogo,
      suusContent,

    };
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/dist/css/bootstrap.css';
.portfolio {
  background: url("~@/assets/LandingBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow-x: hidden;
}
</style>
