<template>
  <section >

      <!-- Add a sidebar button that toggles the sidebar -->
      <button @click="sidebarOpen = !sidebarOpen" class="navbar-toggler" aria-label="Toggle sidebar" style="position: absolute; top: 40px; right: 40px;">
        <div class="animated-icon2" :class="[sidebarOpen && 'open']">
          <span></span><span></span><span></span><span></span>
        </div>
      </button>

      <!-- Sidebar -->
      <aside class="sidebar" :class="{ 'active': sidebarOpen }" >
        <!-- Sidebar content goes here -->
        <div class="sidebar-content" style="display: flex; flex-direction: column; align-items: center;">
          <!-- <router-link class="sidebar-link" to="/portfolio">Portfolio</router-link> -->
          <a class="sidebar-link" href="https://www.scalabledev.de/">Home</a>
          <a class="sidebar-link" href="https://www.scalabledev.de/services">Services</a>
          <a class="sidebar-link" href="https://www.scalabledev.de/projects">Projekte</a>
          <a class="sidebar-link" href="https://www.scalabledev.de/contact">Kontakt</a>
          <a class="sidebar-link" href="https://www.scalabledev.de/privacy">Datenschutz</a>
          <a class="sidebar-link" href="https://www.scalabledev.de/legal-notice">Impressum</a>
        </div>
      </aside>

  </section>
</template>

<style lang="scss">
.sidebar {
  position: fixed;
  right: -250px; /* Start the sidebar outside the view */
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(51, 51, 51, 0.8); /* Transparent background */
  color: white;
  overflow-y: auto;
  transition: right 0.5s; /* Add a smooth transition effect */
  min-height: 80%;
  border-radius: 20px;
  z-index: 1;
}

.navbar-toggler {

}

.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.sidebar-link {
  padding: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
  color: white;
  text-decoration: underline;
  font-size: 24px;
  text-align: center;
}

.sidebar-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.active {
  right: 0; /* Slide the sidebar into view */
}

.animated-icon2 {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}


.animated-icon2 span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.animated-icon2 span {
  background: #e3f2fd;
}

.animated-icon2 span:nth-child(1) {
  top: 0px;
}

.animated-icon2 span:nth-child(2),
.animated-icon2 span:nth-child(3) {
  top: 10px;
}

.animated-icon2 span:nth-child(4) {
  top: 20px;
}

.animated-icon2.open span:nth-child(1) {
  top: 11px;
  width: 0%;
  left: 50%;
}

.animated-icon2.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.animated-icon2.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.animated-icon2.open span:nth-child(4) {
  top: 11px;
  width: 0%;
  left: 50%;
}
</style>

<script>
import {ref, onMounted} from "vue";

export default {
  components: {

  },
  setup() {
    const sidebarOpen = ref(false);

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    const closeSidebar = () => {
      sidebarOpen.value = false;
    };

    onMounted(() => {
      const handleClickOutside = (event) => {
        const sidebar = document.querySelector(".sidebar");
        const button = document.querySelector(".navbar-toggler");

        // Check if the click is outside the sidebar and not on the button
        if (sidebar && !sidebar.contains(event.target) && !button.contains(event.target)) {
          closeSidebar();
        }
      };
      document.addEventListener("click", handleClickOutside);
    });

    return {
      sidebarOpen,
      toggleSidebar,
    };
  },
};
</script>
