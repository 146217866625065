<template>
  <router-view />
</template>

<style lang="scss">
#app {
  font-family: source-code-pro, monospace;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
  min-height: 100vh;
}

.embarcadero-font {
  font-family: embarcadero-mvb-pro-condense, sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  min-height: 100%;
}
</style>
