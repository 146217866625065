<template>
  <footer class="footer">
    <div class="side-part">
      <a href="mailto:info@scalabledev.de?subject=Ich habe eine Idee.." class="contact-button"> Kontaktieren </a>
    </div>
    <div class="middle-part">
      <router-link class="link-item" to="/impressum"> Impressum </router-link>
      <router-link class="link-item" to="/datenschutz"> Datenschutzerklärung </router-link>
    </div>
    <div class="side-part">

    </div>
  </footer>
</template>

<script>
export default {name: 'app-footer'};
</script>

<style>
.footer {
  height: 128px;
  background-color: #212626;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.link-item {
  color: #b5bfae;
  text-decoration: none;
  font-size: 16px;
  font-weight: 700;
  margin: 0 8px;
}

.link-item:hover {
  color: #ffffff;
}

.side-part {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle-part {
  flex-basis: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-button {
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  background: #b5bfae;
  text-decoration: none;
  color: #ffffff;
  border-radius: 10px;
}

.contact-button:hover {
  background: #ffffff;
  color: #b5bfae;
}

@media screen and (max-width: 900px) {
  .footer {
    height: initial;
    min-height: 128px;
    flex-direction: column;
  }

  .contact-button {
    margin: 16px;
  }
}
</style>