<template>
  <div>
    <button @click="scrollToTop" id="scrollToTopBtn" class="scroll-to-top-button" :class="{ hidden: !buttonVisible }">
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      buttonVisible: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkScroll);
  },
  methods: {
    checkScroll() {
      const scrollToTopBtn = document.getElementById("scrollToTopBtn");
      if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
        this.buttonVisible = true;
      } else {
        this.buttonVisible = false;
      }
      scrollToTopBtn.style.display = this.buttonVisible ? "block" : "none";
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
#scrollToTopBtn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  color: white;
  font-size: 50px;
  width: 60px;
  height: 60px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 70px; /* Center vertically */
}

#scrollToTopBtn.hidden {
  display: none;
}

#scrollToTopBtn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

#scrollToTopBtn::before {
  content: "^";
  font-family: "Sitka Banner", sans-serif;
  font-weight: bold;
}
</style>
