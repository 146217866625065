import StudentMock1 from "@/assets/testatio/StudentMock1.png"
import StudentMock2 from "@/assets/testatio/StudentMock2.png"
import StudentMock3 from "@/assets/testatio/StudentMock3.png"
import TeacherMock1 from "@/assets/testatio/TeacherMock1.png"
import TeacherMock2 from "@/assets/testatio/TeacherMock1.png"
import TeacherMock3 from "@/assets/testatio/TeacherMock1.png"
import TestatioWebsite from "@/assets/testatio/TestatioWebsite.png"

const info = {
    styling: {
        name: {
            family: "futura-pt-bold, sans-serif",
            weight: "700",
        },
        subname: {
            family: "futura-pt, sans-serif",
            weight: "600",
        },
        content: {
            family: "futura-pt, sans-serif",
            weight: "400",
            size: "30px",
        },
    },
    content: "Testa.io ist eine Web- und Mobile-App für die Durchführung von digitalen Vokabeltests. Wir entwickelten für Testat.io die Web- und Mobile-App zusammen mit der Webseite, über die das Produkt erworben werden kann."
}

const subsections = [
    {
        images: [StudentMock1, StudentMock2, StudentMock3],
        description: "Schülerversion",
        descriptionStyleClass: "testatio-subsection-style",
    },
    {
        images: [TeacherMock1],
        description: "Lehrerversion",
        descriptionStyleClass: "testatio-subsection-style",
    },
    {
        images: [TestatioWebsite],
        description: "Webseite + Shop",
        descriptionStyleClass: "testatio-subsection-style",
    },
];

const testatioContent = {
    info,
    subsections
}

export default testatioContent;