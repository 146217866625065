<template>
  <div class="portfolio-landing">
    <div class="landing-container">
      <div class="landing-content">
        <div class="image-stack">
          <!-- Stacked images with revealing animation -->
          <div class="stacked-image image1">
            <img src="@/assets/ruhl/RuhlPlan.png" alt="Project 1" />
          </div>
          <div class="stacked-image image2">
            <img src="@/assets/chinder/SpendenSpiel.png" alt="Project 2" />
          </div>
          <div class="stacked-image image3">
            <img src="@/assets/flavoro/control-center.png" alt="Project 3"/>
          </div>
        </div>
        <div class="landing-message">
          <h1>Willkommen in unserem Portfolio</h1>
          <p>Erkunden Sie unsere kreativen Projekte.</p>
          <a @click="scrollToPortfolio" class="explore-button">Jetzt erkunden</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortfolioLanding",
  methods: {
    scrollToPortfolio() {
      // Get the portfolio content container by its ID
      const portfolioContent = document.getElementById('PortfolioSection');

      if (portfolioContent) {
        // Scroll to the portfolio content
        portfolioContent.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/dist/css/bootstrap.css';
.portfolio-landing {
  min-height: 100vh;
  background-color: #212626e6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-container {
  width: 80%;
  max-width: 1200px; /* Adjust the container width as needed */
  text-align: center;
}

.landing-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.image-stack {
  width: 600px; /* Increased size of the image stack */
  height: 600px;
  position: relative;
}

.stacked-image {
  width: 100%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
  position: absolute;
  opacity: 0;
  animation-iteration-count: 1; /* Prevent looping */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image1 {
  animation: fadeIn 2s 0.5s forwards, slideIn 2s 0.5s forwards;
  top: -50px; /* Adjust the top position */
  left: -150px; /* Adjust the left position */
  transform: translate(-50%, -50%);

}

.image2 {
  animation: fadeIn 2s 2.5s forwards, slideIn 2s 2.5s forwards;
  top: 50px; /* Adjust the top position */
  left: -50px; /* Adjust the left position */
  transform: translate(-50%, -50%);
}

.image3 {
  animation: fadeIn 2s 4.5s forwards, slideIn 2s 4.5s forwards;
  top: 170px; /* Adjust the top position */
  left: 70px; /* Adjust the left position */
  transform: translate(-50%, -50%);
}

img {
  max-width: 80%;
  max-height: 80%;
  width: auto;
  height: auto;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  to {
    transform: translateX(0);
  }
}

.landing-message {
  text-align: left;
  color: #fff;
  width: 50%;
  padding: 20px;
}

.landing-message h1 {
  font-size: 36px;
  margin: 0;
  padding: 0;
}

.landing-message p {
  font-size: 18px;
  margin: 10px 0;
  padding: 0;
}

.explore-button {
  display: inline-block;
  background: #f0ad4e;
  color: #333;
  padding: 10px 20px;
  font-size: 20px;
  text-decoration: none;
  border-radius: 25px; /* Rounded edges for the button */
  margin-top: 20px;
  transition: background 0.3s;
}

.explore-button:hover {
  background: #e49d3b; /* Hover background color */
}

@media screen and (max-width: 1023px) {
  .landing-message {
    width: 60%;

  }

  .landing-message h1 {
    font-size: 34px;
  }
}

@media screen and (max-width: 600px) {
  .landing-message {
    padding: 0;
  }

  .landing-message h1 {
    font-size: 30px;
  }

  .landing-message p {
    font-size: 16px;
  }

  .image1 {
    top: -50px;
    left: -10%;
  }

  .image2 {
    top: 50px;
    left: -10%;
  }

  .image3 {
    top: 120px;
    left: -36%;
    width: 150%;
  }

  .explore-button {
    font-size: 16px;
  }
}

@media screen and (max-width: 350px) {
  .landing-content {
    flex-direction: column; /* Change flex direction to column for smaller screens */
  }

  .landing-message {
    width: 100%; /* Make the message take up the full width */
    text-align: center; /* Center-align text */
  }

  .landing-message h1 {
    font-size: 28px; /* Adjust heading font size */
  }

  .landing-message p {
    font-size: 14px; /* Adjust paragraph font size */
  }

  .stacked-image {
    width: 100vw;
    max-height: 300px;
    left: 50%;
  }

  .image1 {
    top: -100px;
    left: 0;
  }

  .image2 {
    top: -30px;
    left: 0;
  }

  .image3 {
    top: 20px;
    left: 0;
    width: 100%;
  }

  .image-stack {
    width: 100vw; /* Increased size of the image stack */
    height: 30vh;
  }
}
</style>
