import SpenderLogin from "@/assets/chinder/SpenderLogin.png";
import SpenderGuthaben from "@/assets/chinder/SpenderGuthaben.png";
import OrgLogin from "@/assets/chinder/OrgLogin.png";
import OrgWillkommen from "@/assets/chinder/OrgWillkommen.png"
import SpendenSpiel from "@/assets/chinder/SpendenSpiel.png"
import SpendenErfolg from "@/assets/chinder/SpendenErfolg.png"
import KeinGuthaben from "@/assets/chinder/KeinGuthaben.png"


const chinderContent = [
    {
        images: [SpenderLogin, SpenderGuthaben],
        description: "Spendersicht",
        descriptionStyleClass: "chinder-subsection-style",
    },
    {
        images: [OrgLogin, OrgWillkommen],
        description: "Organisationssicht",
        descriptionStyleClass: "chinder-subsection-style",
    },
    {
        images: [SpendenSpiel, SpendenErfolg, KeinGuthaben],
        description: "Spendenspiel",
        descriptionStyleClass: "chinder-subsection-style",
    },
];

export default chinderContent;