import Merci1 from "@/assets/merci/merci-1.png"
import Merci2 from "@/assets/merci/merci-2.png"
import Merci3 from "@/assets/merci/merci-3.png"

const info = {
    styling: {
        name: {
            family: "futura-pt-bold, sans-serif",
            weight: "700",
        },
        subname: {
            family: "futura-pt, sans-serif",
            weight: "600",
        },
        content: {
            family: "futura-pt, sans-serif",
            weight: "400",
            size: "30px",
        },
    },
    content: "Merci Finest Selection ist ein einzigartiges, personalisierbares Geschenk, das deine Lieben verzaubern wird. Mit kreativen Gestaltungsmöglichkeiten kannst du ein ganz individuelles Geschenk kreieren. Überrasche deine Lieben mit diesem besonderen Angebot! <br><br><a href='https://www.merci.de/de/designer' style='color: inherit;'>Zum Merci Gestalter</a>"
}

const subsections = [
    {
        images: [Merci1],
        description: "Einfacher Konfigurator",
        descriptionStyleClass: "merci-subsection-style",
    },
    {
        images: [Merci2],
        description: "Auswahl an Designs",
        descriptionStyleClass: "merci-subsection-style",
    },
    {
        images: [Merci3],
        description: "Individuelle Beschriftung",
        descriptionStyleClass: "merci-subsection-style",
    },
];

const merciContent = {
    info,
    subsections
}

export default merciContent;