<template>
  <div class="landing-wrapper">
    <div class="backdrop">
      <div class="hero-container">
        <div class="left-space"></div>
        <div class="hero-content">
          <div class="top-header">Wir setzen Ideen um.</div>
          <div class="main-header reveal-first">Aus <span class="highlight">einer</span> Hand.</div>
          <div class="main-header reveal-second">Mit <span class="highlight">dreifacher</span> Expertise.</div>
          <div class="sub-header reveal-third">Web | Mobile & Crossplattform | Backend | Design & UX | Software Test Management</div>
          <div class="contact-button-area reveal-third">
            <a href="mailto:info@scalabledev.de?subject=Ich habe eine Idee.." class="contact-button"> Kontaktieren </a>
          </div>
        </div>

        <div class="right-space"></div>
      </div>
      <div class="avatar-section reveal-third">
        <div class="avatar first">
          <div class="avatar-text">Ralf Keller</div>
          <div class="avatar-image"><img class="avatar-image" src="@/assets/Ralf.png" alt=""></div>
        </div>
        <div class="avatar first">
          <div class="avatar-text">Stefan Mayer</div>
          <div class="avatar-image"><img class="avatar-image" src="@/assets/Stefan.png" alt=""></div>
        </div>
        <div class="avatar">
          <div class="avatar-text">Alexander Ruhl</div>
          <div class="avatar-image"><img class="avatar-image" src="@/assets/Alexander.png" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
export default {
  name: 'app-landing',
  components: {},
  setup() {
    onMounted(() => {
      /*if (window.scrollY == 0) {
        deactivateScroll();
        setTimeout(enableScroll, 4000);
      } */
    });

    const deactivateScroll = () => {
      const body = document.getElementsByTagName("body")[0];
      body.classList.add("no-scroll");
    };

    const enableScroll = () => {
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove("no-scroll");
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~bootstrap/dist/css/bootstrap.css';
.landing-wrapper {

}

.backdrop {
  height: 100vh;
  width: 100vw;
  background-color: #212626e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.hero-content {
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 0;
}

.left-space {
  flex-basis: 16%;
  flex-shrink: 1;
}

.right-space {
  flex-basis: 34%;
  flex-shrink: 1;
}

.top-header {
  font-size: 32px;
  color: #ffffff54;
}

.highlight {
  color: #b5bfae;
}

.main-header {
  font-size: 64px;
  font-weight: 700;
  margin-bottom: -16px;
  white-space: nowrap;
  opacity: 0;
}

.sub-header {
  text-align: right;
  font-family: embarcadero-mvb-pro-condense, sans-serif;
  font-size: 24px;
  opacity: 0;
}

.contact-button-area {
  opacity: 0;
  margin-top: 64px;
  display: flex;
  flex-direction: row;
}

.contact-button {
  cursor: pointer;
  font-size: 32px;
  font-weight: 700;
  padding: 16px 32px;
  background: #b5bfae;
  text-decoration: none;
  color: #ffffff;
  border-radius: 10px;
}

.contact-button:hover {
  background: #ffffff;
  color: #b5bfae;
}

.avatar-image {
  width: 96px;
  height: 96px;
}

.avatar-section {
  position: absolute;
  right: 32px;
  bottom: 32px;
  display: flex;
  flex-direction: row;
  opacity: 0;
}

.avatar {
  &.first {
    margin-right: 24px;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar-text {
  transform: rotate(-90deg);
  width: 28px;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.reveal-first {
  animation: reveal 1s 1s 1 ease-in-out forwards;
}

.reveal-second {
  animation: reveal 1s 2s 1 ease-in-out forwards;
}

.reveal-third {
  animation: reveal 1s 3s 1 ease-in-out forwards;
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1023px) {
  .top-header {
    font-size: 22px;
  }

  .main-header {
    font-size: 32px;
    margin-bottom: -8px;
  }

  .sub-header {
    font-size: 16px;
  }

  .contact-button {
    font-size: 16px;
    padding: 8px 16px;
  }

  .avatar-image {
    width: 96px;
    height: 96px;
  }
  .avatar-text {
    font-size: 16px;
  }
  .avatar-section {
    position: absolute;
    right: 16px;
    bottom: 26px;
    display: flex;
    flex-direction: row;
  }
}

@media screen and (max-width: 600px) {
  .top-header {
    font-size: 12px;
  }

  .main-header {
    font-size: 22px;
    margin-bottom: -4px;
  }

  .sub-header {
    font-size: 12px;
  }

  .contact-button {
    font-size: 16px;
    padding: 8px 16px;
  }

  .avatar-image {
    width: 64px;
    height: 64px;
  }
  .avatar-text {
    font-size: 12px;
  }

  .avatar-section {
    position: absolute;
    right: 8px;
    bottom: 26px;
    display: flex;
    flex-direction: row;
  }

  .avatar.first {
    margin-right: 8px;
  }
}

@media screen and (max-width: 350px) {
  .main-header {
    font-size: 18px;
    margin-bottom: -4px;
  }

  .sub-header {
    font-size: 9px;
  }
}
</style>

<style lang="scss">
.no-scroll {
  height: 100vh;
  overflow: hidden;
}
</style>
