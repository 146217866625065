import Pic3 from "@/assets/suus/statistik.png"
import Pic1 from "@/assets/suus/forum.png"
import Pic2 from "@/assets/suus/dateiablage.png"

const info = {
    styling: {
        name: {
            family: "futura-pt-bold, sans-serif",
            weight: "700",
        },
        subname: {
            family: "futura-pt, sans-serif",
            weight: "600",
        },
        content: {
            family: "futura-pt, sans-serif",
            weight: "400",
            size: "30px",
        },
    },
    content: "Corpshaus 2.0 ist Ihr digitales Verbindungsnetzwerk. Es vereint Ihr Corps und ermöglicht die Vernetzung mit anderen Verbindungen. Organisieren Sie Veranstaltungen, kommunizieren Sie effizient und haben Sie alle Informationen an einem Ort. Machen Sie sich das Verbindungsleben leichter mit Corpshaus 2.0."
}

const subsections = [
    {
        images: [Pic1],
        description: "Kommunikationsplattform",
        descriptionStyleClass: "suus-subsection-style",
    },
    {
        images: [Pic2],
        description: "Digitales Archiv",
        descriptionStyleClass: "suus-subsection-style",
    },
    {
        images: [Pic3],
        description: "Statistiken",
        descriptionStyleClass: "suus-subsection-style",
    },
];

const suusContent = {
    info,
    subsections
}

export default suusContent;