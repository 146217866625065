import RuhlPlan from "@/assets/ruhl/RuhlPlan.png"
import RuhlExercise from "@/assets/ruhl/RuhlExercise.png"
import RuhlCreation from "@/assets/ruhl/RuhlCreation.png"


const ruhlContent = [
    {
        images: [RuhlPlan],
        description: "Planübersicht",
        descriptionStyleClass: "ruhl-subsection-style",
    },
    {
        images: [RuhlExercise],
        description: "Übungsansicht",
        descriptionStyleClass: "ruhl-subsection-style",
    },
    {
        images: [RuhlCreation],
        description: "Planerstellung",
        descriptionStyleClass: "ruhl-subsection-style",
    },
];

export default ruhlContent;