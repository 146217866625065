<template>
  <section :style="{'background-color' : bgColor, 'color' : textColor}" ref="section" class="section-container">
    <div ref="descColumn" class="portfolio-desc-column">
      <div class="portfolio-desc">
        <div class="desc-header">
          <div ref="descName" :style="{'border-bottom-color': textColor}" class="desc-name expand">
            <div ref="portfolioNumber" class="portfolio-number slidein">Portfolio #{{number}}</div>
            <div ref="nameElement" :style="{'font-family' : styling.name.family, 'font-weight' : styling.name.weight}" class="portfolio-name slidein">{{name}}</div>
            <div ref="subnameElement" :style="{'font-family' : styling.subname.family, 'font-weight' : styling.subname.weight}" class="portfolio-subname slidein">{{subname}}</div>
          </div>
          <div class="desc-logo">
            <img ref="logoElement" class="logo-img slidein" :src="logo" alt="">
          </div>
        </div>
        <div ref="contentElement" :style="{'font-family' : styling.content.family, 'font-weight' : styling.content.weight, 'font-size' : styling.content.size}" class="desc-content reveal" v-html="content"></div>
      </div>
    </div>
    <div class="portfolio-expo">
      <slot> </slot>
    </div>
  </section>
</template>

<script>
import { ref, onMounted } from "vue";
import flavoroContent from "@/assets/flavoro/flavoro-content.js";
import ruhlContent from "@/assets/ruhl/ruhl-content.js";
import testatioContent from "@/assets/testatio/testatio-content.js";
import chinderContent from "@/assets/chinder/chinder-content.js";
import fdpContent from "@/assets/fdp/fdp-content.js";

export default {
  props: [
    "number",
    "name",
    "subname",
    "bgColor",
    "textColor",
    "content",
    "styling",
    "logo",
  ],
  components: {
    // PortfolioSubsection,
  },
  setup() {
    const hidden = ref(true);
    const toHide = ref(true);
    const section = ref(null);
    const descColumn = ref(null);

    const portfolioNumber = ref(null);
    const nameElement = ref(null);
    const subnameElement = ref(null);
    const logoElement = ref(null);
    const descName = ref(null);
    const contentElement = ref(null);

    const scrollTimer = ref(null);

    onMounted(() => {
      console.log(section.value);
      window.addEventListener("scroll", handleScroll);
    });

    const handleScroll = (event) => {
      clearTimeout(scrollTimer.value);

      const topShowThreshold =
        section.value.offsetTop - window.innerHeight * 0.2;

      const offsetBottom = section.value.offsetTop + section.value.offsetHeight;
      const bottomShowThreshold = offsetBottom + window.innerHeight * 0.2;

      const scrollBottomPosition = window.scrollY + window.innerHeight;

      if (
        window.scrollY > topShowThreshold &&
        scrollBottomPosition < bottomShowThreshold
      ) {
        if (hidden.value) {
          scrollTimer.value = setTimeout(showElements, 125);
        }
      } else if (!hidden.value) {
        hidden.value = true;
        hideElements();
      }
    };

    const showElements = () => {
      console.log("show elements!");
      hidden.value = false;
      toHide.value = true;
      descColumn.value.classList.remove("show");
      descColumn.value.classList.remove("hide");
      void descColumn.value.offsetWidth;
      descColumn.value.classList.add("show");
    };

    const hideElements = () => {
      console.log("hide elements!");
      descColumn.value.classList.add("hide");
      toHide.value = false;
      setTimeout(() => {
        if (toHide.value == false) {
          descColumn.value.classList.remove("show");
          console.log("timeout!");
        }
      }, 500);
    };

    return {
      section,
      descColumn,
      portfolioNumber,
      nameElement,
      subnameElement,
      logoElement,
      descName,
      contentElement,
      flavoroContent,
      ruhlContent,
      testatioContent,
      chinderContent,
      fdpContent,
    };
  },
};
</script>

<style>
.section-container {
  display: grid;
  grid-template-columns: 40vw 60vw;
  min-height: 100vh;
}

.subsection {
  height: 100vh;
  color: black;
}

.portfolio-expo {
  grid-column: 2;
}

.portfolio-desc-column {
  /*height: 100vh;*/
  max-width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%);
}

.portfolio-desc {
  padding: 0 16px;
  max-width: 604px;
  overflow: hidden;
}

.desc-header {
  display: flex;
  flex-direction: row;
}

.desc-name {
  flex-grow: 2;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.desc-name:after {
  margin-top: 12px;
  display: block;
  content: "";
  border-bottom: solid 3px;
  width: 0px;
}

.desc-logo {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.logo-img {
  margin-left: -400px;
}

.desc-content {
  margin-top: 32px;
  font-size: 32px;
  opacity: 0;
}

.portfolio-number {
  font-size: 32px;
  margin-left: -400px;
}

.portfolio-name {
  font-size: 64px;
  margin-left: -400px;
}

.portfolio-subname {
  font-size: 25px;
  margin-left: -400px;
}

.slidein {
  animation: slidein 2s 1 forwards;
}

.expand::after {
  width: 0px;
  animation: expand 2s 2s 1 forwards;
}

.reveal {
  animation: reveal 2s 2s 1 forwards;
}

.show {
  display: flex;
  opacity: 1;
}

.hide {
  animation: hide 0.5s 1 forwards;
}

.hide .desc-name::after {
  animation: expand 2s 1 reverse backwards;
}

.alt {
  background: black;
}

@keyframes slidein {
  0% {
    margin-left: -400px;
  }

  100% {
    margin-left: 0px;
  }
}

@keyframes expand {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 1200px) {
  .slidein {
    animation: initial;
  }

  .expand::after {
    animation: initial;
    width: 100%;
  }

  .reveal {
    animation: initial;
  }

  .show {
    opacity: 1;
  }

  .hide {
    animation: initial;
  }

  .hide .desc-name::after {
    animation: initial;
  }

  .logo-img {
    margin-left: 0;
  }

  .portfolio-number {
    font-size: 32px;
    margin-left: 0;
  }

  .portfolio-name {
    font-size: 64px;
    margin-left: 0;
  }

  .portfolio-subname {
    font-size: 25px;
    margin-left: 0;
  }

  .portfolio-desc-column {
    opacity: 1;
    position: relative;
    max-width: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    height: initial;
    min-height: 50vh;
    margin: 64px;
    top: initial;
    transform: initial;
  }

  .subsection {
    height: initial;
    min-height: 50vh;
    margin-bottom: 96px;
  }

  .desc-content {
    opacity: 1;
  }

  .section-container {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .portfolio-number {
    font-size: 16px;
    white-space: nowrap;
  }

  .portfolio-name {
    font-size: 32px;
  }

  .portfolio-subname {
    font-size: 16px;
  }

  .desc-content {
    font-size: 16px !important;
  }

  .logo-img {
    width: 90%;
  }

  .portfolio-desc-column {
    margin: 16px;
    margin-top: 32px;
  }

  .subsection {
    margin: 32px;
  }
}

@import url("../assets/ruhl/ruhl-style.css");
@import url("../assets/testatio/testatio-style.css");
@import url("../assets/chinder/chinder-style.css");
@import url("../assets/fdp/fdp-style.css");
@import url("../assets/flavoro/flavoro-style.css");
@import url("../assets/tremaze/tremaze-style.css");
@import url("../assets/merci/merci-style.css");
@import url("../assets/suus/suus-style.css");
</style>