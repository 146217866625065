<template>
  <main class="wrapper">
    <router-link to="/">
      <div class="back-button"> Zurück </div>
    </router-link>

    <h1>Impressum</h1>
    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>Keller, Mayer GbR<br />
      Briloner Landstr. 87<br />
      34497 Korbach</p>
    <h2>Kontakt</h2>
    <p>
      Vertretung durch:&nbsp;Stefan Mayer<br />
      Telefon:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+49 152 36757307<br />
      E-Mail:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;info@scalabledev.de<br />
      Umsatzsteuer-ID:&nbsp;&nbsp;DE319781395</p>
    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
  </main>
</template>

<script>
export default {name: 'impressum-footer'};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: #212626;
  padding: 64px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  overflow-x: hidden;
}

.highlight {
  color: #b5bfae;
}

h1 {
  font-size: 64px;
  margin: 0;
  margin-bottom: 32px;
  margin-top: 32px;
}

h2 {
  font-size: 32px;
  font-weight: 700;
  margin-top: 16px;
}

a {
  text-decoration: none;
}

.back-button {
  padding: 8px 16px;
  background: #b5bfae;
  color: white;
  text-decoration: none;
  font-weight: 700;
  border-radius: 10px;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    padding: 16px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 16px;
  }
}
</style>>