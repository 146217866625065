import FDPWebsite from "@/assets/fdp/FDPWebsite.png";
import FDPPrint from "@/assets/fdp/FDPPrint.png";

const fdpContent = [
    {
        images: [FDPWebsite],
        description: "Website + Social Media Posts",
        descriptionStyleClass: "fdp-subsection-style",
    },
    {
        images: [FDPPrint],
        description: "Großflächenplakat + Litfaßsäule",
        descriptionStyleClass: "fdp-subsection-style",
    },
];

export default fdpContent;