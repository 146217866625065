<template>
  <div class="subsection flex-center">
    <div class="images-wrapper flex-center">
      <div v-for="(image,index) of content.images" :key="index" class="img-container flex-center">
        <img class="expo-image" :src="image" alt="">
      </div>
    </div>
    <div :class="content.descriptionStyleClass" class="desc">{{content.description}}</div>
  </div>
</template>

<script>
export default {
  props: ["content"],
};
</script>

<style lang="scss" scoped>
.subsection {
  min-height: 100vh;
  flex-direction: column;
  .images-wrapper {
    flex-direction: row;
    .expo-image {
      max-width: 80%;
      height: auto;
      max-height: 500px;
    }
  }
  /** indiviual text style is passed as a class through props */
  .desc {
    font-size: 64px;
    text-align: center;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .subsection {
    min-height: 50vh;
  }
}

@media screen and (max-width: 600px) {
  .subsection {
    flex-direction: column-reverse;
    .images-wrapper {
      flex-direction: column;
      .img-container {
        margin-bottom: 32px;
        .expo-image {
          max-width: 100%;
        }
      }
    }

    .desc {
      font-size: 32px;
      margin-bottom: 32px;
    }
  }
}
</style>