<template>
  <div class="home">
    <Landing />
    <!--   <Unternehmen /> -->
    <!-- <ScalableDevInfo />-->
     <DirectToPortfolio />
    <!-- <ITServices />-->
     <NavigationMenu />
    <ScrollButton />
     <Footer />
   </div>
 </template>

 <script>
 import Footer from "@/components/Footer.vue";
 import ScrollButton from "@/components/ScrollButton.vue";
 import NavigationMenu from "@/components/NavigationMenu.vue";
 import Landing from "@/components/Landing.vue";
 import DirectToPortfolio from "@/components/DirectToPortfolio.vue";

 export default {
   name: "app-home",
   components: {
     Landing,
     NavigationMenu,
     Footer,
     ScrollButton,
     DirectToPortfolio,
   },

 };
 </script>

 <style lang="scss" scoped>
 @import '~bootstrap/dist/css/bootstrap.css';
 .home {
   background: url("~@/assets/LandingBackground.png");
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   background-attachment: fixed;
   overflow-x: hidden;
 }
 </style>