import Oeffnungszeiten from "@/assets/flavoro/oeffnungszeiten.png"
import Speisekarte from "@/assets/flavoro/speisekarte.png"
import ControlCenter from "@/assets/flavoro/control-center.png"

const info = {
    styling: {
        name: {
            family: "futura-pt-bold, sans-serif",
            weight: "700",
        },
        subname: {
            family: "futura-pt, sans-serif",
            weight: "600",
        },
        content: {
            family: "futura-pt, sans-serif",
            weight: "400",
            size: "30px",
        },
    },
    content: "Flavoro ist die Lösung für Restaurants, die eine mühelose Webseite möchten. Mit automatischer Webseitenerstellung und Anpassungen an die Gastronomie bietet Flavoro eine professionelle Online-Präsenz. Unser Ziel ist es, Restaurants im besten Licht zu präsentieren. <br><br><a href='https://www.flavoro.de/' style='color: inherit;'>Zu Flavoro</a>"
}

const subsections = [
    {
        images: [ControlCenter],
        description: "Control Center",
        descriptionStyleClass: "flavoro-subsection-style",
    },
    {
        images: [Speisekarte],
        description: "Speisekarte",
        descriptionStyleClass: "flavoro-subsection-style",
    },
    {
        images: [Oeffnungszeiten],
        description: "Öffnungszeiten",
        descriptionStyleClass: "flavoro-subsection-style",
    },
];

const flavoroContent = {
    info,
    subsections
}

export default flavoroContent;