import Tremaze from "@/assets/tremaze/tremaze.png"
import NeuerInhalt from "@/assets/tremaze/NeuerInhalt.png"
import ChatFunktion from "@/assets/tremaze/ChatFunktion.png"

const info = {
    styling: {
        name: {
            family: "futura-pt-bold, sans-serif",
            weight: "700",
        },
        subname: {
            family: "futura-pt, sans-serif",
            weight: "600",
        },
        content: {
            family: "futura-pt, sans-serif",
            weight: "400",
            size: "30px",
        },
    },
    content: "Tagea revolutioniert die Kommunikation und Dokumentation in sozialen Einrichtungen. Von partizipativer, datenschutzkonformer Kommunikation bis zur einfachen Terminplanung und Dokumentation. Entdecken Sie, wie Tagea die soziale Arbeit effizienter gestaltet."
}

const subsections = [
    {
        images: [Tremaze],
        description: "Digitale Kommunikation",
        descriptionStyleClass: "tremaze-subsection-style",
    },
    {
        images: [NeuerInhalt],
        description: "Einfach Inhalte anlegen",
        descriptionStyleClass: "tremaze-subsection-style",
    },
    {
        images: [ChatFunktion],
        description: "Integrierte Chatfunktion",
        descriptionStyleClass: "tremaze-subsection-style",
    },
];

const tremazeContent = {
    info,
    subsections
}

export default tremazeContent;